import * as React from 'react';
import Article from '../components/ArticleListItem';
import Layout from '../components/Layout';
import Proptypes from 'prop-types';
import {Box, Flex, Grid, GridItem, Heading, Text} from '@chakra-ui/react';
import {FaBookDead} from '@react-icons/all-files/fa/FaBookDead';
import {FaStarHalfAlt} from '@react-icons/all-files/fa/FaStarHalfAlt';

import {Link as GatsbyLink, graphql} from 'gatsby';

const cards = [
  {
    title: 'Definitions',
    link: '/definitions',
    description: (
      <Text>
        <Text fontStyle="italic">
          Ca veut dire quoi &quot;up and down&quot;?
        </Text>
        <Text fontStyle="italic">
          C&apos;est quoi le &quot;dynamique loft&quot;?
        </Text>
        Dans cette section, retrouvez des définitions <u>intelligibles</u> par
        tous, pour les termes de golf courrants, avec des exemples concrets.
      </Text>
    ),
    icon: <FaBookDead />
  },
  {
    title: 'Tests',
    description: (
      <Text>
        <Text fontStyle="italic">
          &quot;Le Driver qui corrige votre slice&quot;
        </Text>
        <Text fontStyle="italic">
          &quot;Cette balle vous fera gagner 50m!&quot;
        </Text>
        Tellement de nouveau materiel, de buzzword, de marketing, qu&apos;il est
        souvent difficile de se faire une idée.
      </Text>
    ),
    link: '/tests',
    icon: <FaStarHalfAlt />
  }
];

function HomeCard({title, link, description, icon}) {
  return (
    <GridItem
      w="100%"
      minH="32"
      bg="green.100"
      color="green.700"
      _hover={{bg: 'green.200', cursor: 'pointer'}}
      borderRadius="md"
      p="8"
      as={GatsbyLink}
      to={link}
    >
      <Flex align="center" justify="space-between">
        <Box>
          <Heading as="h3" size="lg" mb="2">
            {title}
          </Heading>
          <Text>{description}</Text>
        </Box>
        {icon && (
          <Text fontSize="5xl" color="green.500">
            {icon}
          </Text>
        )}
      </Flex>
    </GridItem>
  );
}

HomeCard.propTypes = {
  title: Proptypes.string.isRequired,
  link: Proptypes.string.isRequired,
  description: Proptypes.string.isRequired,
  icon: Proptypes.string.isRequired
};

export default function index({data}) {
  const {articles} = data.allMdx;

  return (
    <Layout>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} my="6">
        {cards.map(card => (
          <HomeCard key={card.title} {...card} />
        ))}
      </Grid>
      <Text fontWeight="700" fontSize="xl" color="green.700">
        Les derniers articles
      </Text>
      {articles.map(blog => (
        <Article
          key={blog.slug}
          link={blog.slug}
          categorie={blog.frontmatter.categorie}
          title={blog.frontmatter.title}
          date={blog.frontmatter.date}
          excerpt={blog.excerpt}
        />
      ))}
    </Layout>
  );
}

export const query = graphql`
  query BlogList {
    allMdx(
      filter: {
        frontmatter: {published: {ne: false}, categorie: {ne: "definition"}}
      }
    ) {
      articles: nodes {
        excerpt
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY", locale: "fr-FR")
          categorie
        }
        slug
        timeToRead
      }
    }
  }
`;
