import PropTypes from 'prop-types';
import React from 'react';
import {Badge, Box, HStack, Heading, Text} from '@chakra-ui/react';
import {Link} from 'gatsby';

export default function Article({link, title, date, categorie = '', excerpt}) {
  return (
    <Link to={link}>
      <Box paddingY="4">
        <Heading
          as="h2"
          size="lg"
          color="green.600"
          fontFamily={'rig-solid-bold-inline-solo, sans-serif'}
          font-weight="700"
        >
          {title}
        </Heading>
        <HStack align="flex-start">
          <Badge colorScheme={'green'}>{categorie}</Badge>
          <Text color="green.600" fontSize={'sm'}>
            {date}
          </Text>
        </HStack>
        <Text>{excerpt}</Text>
      </Box>
    </Link>
  );
}

Article.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  categorie: PropTypes.string,
  excerpt: PropTypes.string
};
